<template>
  <section>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />
    <base-loading v-if="loadingContent" class="my-8" />
    <template v-else>
      <div class="d-flex justify-center">
        <base-dialog
          :title="$t('addAddress')"
          :labelBtnActivator="$t('addAddress')"
        >
          <template #content>
            <AddAddressForm />
          </template>
        </base-dialog>
      </div>
      <section class="d-flex justify-center flex-wrap py-4">
        <template v-if="iHaveAddresses">
          <UserAddress
            v-for="address in addresses"
            :key="address.id"
            :address="address"
            :isSelectable="isSelectable"
            class="my-2 mx-1"
            @delete-an-address="deleteAnAddress"
          />
        </template>
        <h3 class="my-5 text-h3 red--text text-lighten-3" v-else>
          {{ $t("notAddress") }}
        </h3>
      </section>
      <div
        id="container__btn-continueOrder"
        class="my-5 d-flex mx-auto"
        v-if="isSelectable"
      >
        <ContinueOrderBtn> Some Content </ContinueOrderBtn>
      </div>
    </template>
  </section>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import { addressesCtrl } from "@/controllers";
import { getErrorPayloadForSnackbar } from "@/helpers";
import { orderTypeStoreGetters } from "@/store/modules/orderType/constNames";

export default {
  name: "UserAddresses",
  inject: ["theme"],
  provide() {
    return {
      createAnAddress: this.createAnAddress,
    };
  },
  components: {
    UserAddress: () => import("@/components/userSettings/UserAddress"),
    AddAddressForm: () => import("@/components/userSettings/AddAddressForm"),
    ContinueOrderBtn: () =>
      import("@/components/shared/SettingOrder/buttons/ContinueOrderBtn"),
  },
  props: {
    subtitle: String,
    title: {
      type: String,
      default: i18n.t("addresses"),
    },
  },
  data() {
    return {
      addresses: [],
      loadingContent: true,
    };
  },
  mounted() {
    addressesCtrl
      .getAddresses()
      .then((addresses) => {
        this.addresses = [...addresses];
      })
      .catch((error) => {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      })
      .finally(() => {
        this.loadingContent = false;
      });
  },
  methods: {
    deleteAnAddress(id) {
      this.addresses = [
        ...this.addresses.filter((address) => address.id !== id),
      ];
    },
    createAnAddress(newAddress) {
      this.addresses = [...this.addresses, newAddress];
    },
  },
  computed: {
    isSelectable() {
      return this.$route.params.isSelectable;
    },
    iHaveSelectAnAddress() {
      return this.$store.getters[orderTypeStoreGetters.GET_ADDRESS];
    },
    iHaveAddresses() {
      return this.addresses.length > 0;
    },
  },
};
</script>

<style lang="sass">
#container__btn-continueOrder
  max-width: max-content
</style>
